<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <view-skeleton>
      <profile />
    </view-skeleton>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import Profile from '@/components/profile/profile';

import DefaultHeadMixin from '@/mixins/defaultHead';
import ViewSkeleton from '@/components/utils/view-skeleton';

export default {
  mixins: [DefaultHeadMixin],
  components: {
    DefaultLayout,
    ViewSkeleton,
    Profile,
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: this.$t('home.sidebarMenu.profile'),
          to: this.$router.currentRoute.path,
        },
      ];
    },
  },
  methods: {},
  mounted() {
    this.$store.dispatch('global/fetch');
  },
};
</script>
