<template>
  <div class="form--group" :class="{ 'has-error': validation.hasError('value') }">
    <div class="left--col">
      <label :for="key">
        <span>{{ title }}</span>
      </label>
    </div>
    <div class="right--col">
      <input :id="key" v-model="value" :placeholder="title" class="basic--input" />
      <span class="val-error">{{ validation.firstError('value') }}</span>
    </div>
  </div>
</template>

<script>
import { Validator } from 'simple-vue-validator';
import HelperMixin from '@/mixins/helpers';
export default {
  props: ['field', 'initValue'],
  mixins: [HelperMixin],
  components: {},
  data() {
    return {
      key: null,
      regex: null,
      value: null,
    };
  },
  computed: {
    title() {
      if (this.key && this.key.length > 1) {
        return this.key[0].toUpperCase() + this.key.substring(1);
      } else {
        return null;
      }
    },
  },
  validators: {
    value(value) {
      let validator = Validator.value(value);
      if (this.regex) {
        validator = validator.regex(
          this.regex.slice(1, -1),
          this.$i18n.t('errors.field.regex', { name: this.title }),
        );
      }

      return validator;
    },
  },
  mounted() {
    this.setInitial(this.field);
  },
  methods: {
    setInitial(field) {
      let self = this;
      this.$nextTick(() => {
        self.key = field.socmed;
        self.regex = field.regex ? atob(field.regex) : null;
        self.value = this.initValue;
      });
    },
    validate() {
      let validationArray = [this.$validate()];
      return Promise.all(validationArray).then(function(results) {
        if (
          results.filter(function(result) {
            return !result;
          }).length === 0
        ) {
          return true;
        }
        return false;
      });
    },
  },
};
</script>

<style lang="scss"></style>
