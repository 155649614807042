<template>
  <section class="profile--wrapper">
    <section-loader :show="loading" />
    <div class="title--text">
      {{ $t('home.sidebarMenu.profile') }}
    </div>
    <change-avatar />
    <form @submit.prevent="updateProfile">
      <div class="input--wrapper">
        <div class="form--group">
          <div class="left--col">
            <label for="name">
              <span>{{ $t('profile.name') }}</span>
            </label>
          </div>
          <div class="right--col">
            <input id="name" v-model="fullname" placeholder="Name" class="basic--input" />
          </div>
        </div>
        <div class="form--group">
          <div class="left--col">
            <label for="email">
              <span>{{ $t('loginRegister.email') }}</span>
            </label>
          </div>
          <div class="right--col">
            <input readonly id="email" v-model="email" placeholder="Nama" class="basic--input" />
          </div>
        </div>
        <div class="form--group" :class="{ 'has-error': validation.hasError('phone') }">
          <div class="left--col">
            <label for="phone">
              <span>{{ $t('profile.phone') }}</span>
            </label>
          </div>
          <div class="right--col">
            <client-only>
              <vue-tel-input
                id="phone"
                :value="phone ? phone : ''"
                @input="phoneInput"
                :validCharactersOnly="true"
                defaultCountry="sg"
              />
            </client-only>
            <span class="val-error">{{ validation.firstError('phone') }}</span>
          </div>
        </div>
        <div class="form--group form--group-switch">
          <div class="left--col">
            <label for="agentRegNo">
              <span>{{ 'Are you an agent?' }}</span>
            </label>
          </div>
          <div class="right--col">
            <button
              type="button"
              class="btn px-4"
              :class="{ selected: isAgent }"
              @click="isAgent = true"
            >
              {{ $t('general.yes') }}
            </button>
            <button
              type="button"
              class="btn px-4"
              :class="{ selected: !isAgent }"
              @click="isAgent = false"
            >
              <span style="position: relative;">
                {{ $t('general.no') }}
              </span>
            </button>
          </div>
        </div>
        <form-transition>
          <div v-show="isAgent">
            <div class="form--group" :class="{ 'has-error': validation.hasError('agentRegNo') }">
              <div class="left--col">
                <label for="agentRegNo">
                  <span>{{ $t('loginRegister.agentRegNo') }}</span>
                </label>
              </div>
              <div class="right--col">
                <input
                  id="agentRegNo"
                  v-model="agentRegNo"
                  placeholder="Agent Registration Number"
                  class="basic--input"
                />
                <span class="val-error">{{ validation.firstError('agentRegNo') }}</span>
              </div>
            </div>
            <div class="form--group" :class="{ 'has-error': validation.hasError('jobTitle') }">
              <div class="left--col">
                <label for="jobTitle">
                  <span>{{ $t('loginRegister.jobTitle') }}</span>
                </label>
              </div>
              <div class="right--col">
                <input
                  id="jobTitle"
                  v-model="jobTitle"
                  placeholder="Job Title"
                  class="basic--input"
                />
                <span class="val-error">{{ validation.firstError('jobTitle') }}</span>
              </div>
            </div>
          </div>
        </form-transition>
        <div class="form--group">
          <div class="left--col">
            <label for="about_me">
              <span>{{ $t('profile.aboutMe') }}</span>
            </label>
          </div>
          <div class="right--col">
            <textarea
              v-model="about_me"
              :placeholder="$t('profile.aboutMe')"
              id="about_me"
              class="basic--input"
              type="text"
              rows="4"
            />
          </div>
        </div>
      </div>
      <social-media ref="socialMediaField"></social-media>

      <div class="main--btn">
        <button class="btn btn-primary" type="submit">
          {{ $t('profile.saveBtn') }}
        </button>
      </div>
    </form>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import ChangeAvatar from '@/components/profile/change-avatar.vue';
import { Validator } from 'simple-vue-validator';
import HelperMixin from '@/mixins/helpers';
import FormTransition from '@/components/utils/form-transition';

import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
const SectionLoader = () => import('@/components/content-loading/section-loading');
import SocialMedia from '@/components/profile/partials/social-media';

export default {
  components: { VueTelInput, ChangeAvatar, FormTransition, SectionLoader, SocialMedia },
  mixins: [HelperMixin],
  data() {
    return {
      phoneCountryCode: null,
      isAgent: false,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      user: state => state.global.user,
      selectedSocmed: state => state.v2.profile.selectedSocmed,
    }),
    fullname: {
      get() {
        return this.$store.state.global.user.fullname;
      },
      set(fullname) {
        this.$store.commit('global/set_user_input', { fullname });
      },
    },
    email: {
      get() {
        return this.$store.state.global.user.email;
      },
      set(email) {
        this.$store.commit('global/set_user_input', { email });
      },
    },
    phone: {
      get() {
        return this.$store.state.global.user.phone;
      },
      set(phone) {
        this.$store.commit('global/set_user_input', { phone });
      },
    },
    about_me: {
      get() {
        return this.$store.state.global.user.about_me;
      },
      set(about_me) {
        this.$store.commit('global/set_user_input', { about_me });
      },
    },
    agentRegNo: {
      get() {
        return this.$store.state.global.user.agent_reg_no;
      },
      set(agent_reg_no) {
        this.$store.commit('global/set_user_input', { agent_reg_no });
      },
    },
    jobTitle: {
      get() {
        return this.$store.state.global.user.job_title;
      },
      set(job_title) {
        this.$store.commit('global/set_user_input', { job_title });
      },
    },
  },
  validators: {
    phone: {
      validator(value) {
        return Validator.value(value).required(this.$i18n.t('errors.phone.required'));
      },
    },
    agentRegNo: {
      validator(value) {
        if (!this.isAgent) return;
        return Validator.value(value).required(this.$i18n.t('errors.agentRegNo.required'));
      },
    },
    jobTitle: {
      validator(value) {
        if (!this.isAgent) return;
        return Validator.value(value).required(this.$i18n.t('errors.jobTitle.required'));
      },
    },
  },
  async mounted() {
    this.isAgent = this.agentRegNo !== null && this.agentRegNo !== undefined;
    this.$store.commit(
      'v2/profile/SET_SOCMED_LIST',
      this.user.social_media_rules ? this.user.social_media_rules : [],
    );
    if (this.user.social_medias) {
      this.$store.commit('v2/profile/SET_SELECTED_SOCMED', this.user.social_medias);
    }
  },
  methods: {
    phoneInput(phone, phoneObject) {
      if (phoneObject && phoneObject.countryCode) {
        this.phoneCountryCode = phoneObject.countryCode;
      } else {
        this.phoneCountryCode = null;
      }
      this.$store.commit('global/set_user_input', { phone });

      // this.$store.commit('global/set_user_input', {phone} );
    },
    validate() {
      let validationArray = [this.$validate(), this.$refs.socialMediaField.validate()];
      return Promise.all(validationArray).then(function(results) {
        if (
          results.filter(function(result) {
            return !result;
          }).length === 0
        ) {
          return true;
        }
        return false;
      });
    },
    async updateProfile() {
      const isValid = await this.validate();
      if (isValid) {
        try {
          this.loading = true;
          let requestData = {
            fullname: this.fullname,
            phone: this.phone,
            about_me: this.about_me,
            phone_country_code: this.phoneCountryCode,
            social_medias: this.selectedSocmed,
          };
          if (this.isAgent) {
            requestData.agent_reg_no = this.agentRegNo;
            requestData.job_title = this.jobTitle;
          }
          let data = await this.$store.dispatch('v2/profile/updateProfile', requestData);
          if (data) {
            this.$swal('Success', this.$t('profile.updateSuccess'), 'success');
            this.$store.dispatch('global/fetch');
          }
          // eslint-disable-next-line no-empty
        } catch (e) {
          console.log('ERROR PROFILE FORM: ', e);
        } finally {
          this.loading = false;
        }
      } else {
        this.scrollToErrorSection(this.$el);
      }
    },
  },
};
</script>
